import * as yup from 'yup'

const MAX_TEXT_FIELD = 50

const LABEL = {
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email',
  RETAILER_NAME: 'Retailer name',
  ROLE: 'Your role',
  LOCATION: 'Your location',
  COUNTRIES: 'Store locations',
  ISMANAGER: 'Store Manager',
}

const ERR_MESSAGE = {
  LOCATION: 'Your location is required',
  RETAILERS: 'At least one retailer is required',
  COUNTRIES: 'At least one store location is required',
  ISMANAGER: 'Role is required',
}

export const locationOptions = [
  {
    value: 'store',
    label: 'Store',
  },
  {
    value: 'headOffice',
    label: 'Head office',
  },
]

export const isManagerOptions = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export const countriesOptions = [
  {
    value: 'au',
    label: 'AU',
  },
  {
    value: 'nz',
    label: 'NZ',
  },
]

export const rbacSchema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .max(MAX_TEXT_FIELD)
    .label(LABEL.FIRST_NAME),
  lastName: yup.string().required().max(MAX_TEXT_FIELD).label(LABEL.LAST_NAME),
  email: yup.string().email().required().max(MAX_TEXT_FIELD).label(LABEL.EMAIL),
  retailerName: yup.string().max(MAX_TEXT_FIELD).label(LABEL.RETAILER_NAME),
  retailerSelection: yup
    .array()
    .max(MAX_TEXT_FIELD)
    .label(LABEL.RETAILER_NAME)
    .when('retailerName', {
      is: (retailerName: string | null) => !retailerName,
      then: schema => schema.min(1, ERR_MESSAGE.RETAILERS),
    }),
  role: yup.string().label(LABEL.ROLE),
  isManager: yup.string().when('retailerSelection', {
    is: (retailerSelection: Array<any>) => retailerSelection?.length <= 1,
    then: yup
      .string()
      .nullable()
      .required(ERR_MESSAGE.ISMANAGER)
      .oneOf(
        isManagerOptions.map(opt => opt.value),
        ERR_MESSAGE.ISMANAGER
      )
      .label(LABEL.ISMANAGER),
  }),
  stores: yup.mixed().when(['isManager', 'isStores'], {
    is: (isManager: string, isStores: boolean) =>
      isManager === 'yes' && isStores,
    then: yup.array().min(1, ERR_MESSAGE.COUNTRIES).label(LABEL.COUNTRIES),
  }),
})

export default yup.object().shape({
  firstName: yup
    .string()
    .required()
    .max(MAX_TEXT_FIELD)
    .label(LABEL.FIRST_NAME),
  lastName: yup.string().required().max(MAX_TEXT_FIELD).label(LABEL.LAST_NAME),
  email: yup.string().email().required().max(MAX_TEXT_FIELD).label(LABEL.EMAIL),
  retailerName: yup.string().max(MAX_TEXT_FIELD).label(LABEL.RETAILER_NAME),
  retailerSelection: yup
    .array()
    .max(MAX_TEXT_FIELD)
    .label(LABEL.RETAILER_NAME)
    .when('retailerName', {
      is: (retailerName: string | null) => !retailerName,
      then: schema => schema.min(1, ERR_MESSAGE.RETAILERS),
    }),
  role: yup.string().max(MAX_TEXT_FIELD).label(LABEL.ROLE),
  location: yup
    .string()
    .nullable()
    .required(ERR_MESSAGE.LOCATION)
    .oneOf(
      locationOptions.map(opt => opt.value),
      ERR_MESSAGE.LOCATION
    )
    .label(LABEL.LOCATION),
  countries: yup
    .array()
    .min(1, ERR_MESSAGE.COUNTRIES)
    .of(yup.string().oneOf(countriesOptions.map(opt => opt.value)))
    .label(LABEL.COUNTRIES),
})
