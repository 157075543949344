import React, { forwardRef } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroupWidget from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import useStyles from './styles'
import { CSSFlexDirection } from 'lib/types/styles'
import TrackBox from 'components/Track/TrackBox'
import { sanitizeId } from 'lib/utils'

interface Props {
  error?: string
  options: {
    label: string
    value: string
  }[]
  orientation?: CSSFlexDirection
  value: string | null
  name: string
  'data-test-id'?: string
}
const RadioGroup = forwardRef<HTMLInputElement, Props>(function RadioGroup(
  { options, orientation, error, 'data-test-id': testId = '', ...rest },
  ref
) {
  const classes = useStyles({ orientation })

  function handleErrorMessage() {
    if (error) {
      return (
        <span
          className={classes.error}
          data-test-id={testId ? `${testId}-error-message` : 'error-message'}
        >
          <span>{error}</span>
        </span>
      )
    }
    return null
  }

  return (
    <Box className={classes.container}>
      <RadioGroupWidget
        className={classes.radioGroup}
        value={rest.value}
        ref={ref}
        data-test-id={testId ? `${testId}-radio-group` : 'radio-group'}
      >
        {options.map(option => (
          <TrackBox id={option.label} key={option.value}>
            <FormControlLabel
              {...rest}
              className={classes.radio}
              value={option.value}
              control={<Radio color="secondary" />}
              label={option.label}
              data-test-id={`${testId}-${sanitizeId(option.value)}`}
            />
          </TrackBox>
        ))}
      </RadioGroupWidget>
      <FormHelperText component={handleErrorMessage} />
    </Box>
  )
})

export default RadioGroup
