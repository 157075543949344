import { InputLabelProps } from '@mui/material/InputLabel/InputLabel'
import { SelectProps } from '@mui/material/Select/Select'
import React, { forwardRef, PropsWithChildren } from 'react'
import SelectMUI from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import useStyles from './styles'
import useTracking from 'hooks/useTracking'
import { TrackIdType } from 'lib/types/gtm'
import { MySelectDisplayProps } from 'lib/types/form'

type SelectOption = { value?: string; label: string }

type Props<Value = unknown> = PropsWithChildren<
  Omit<SelectProps<Value>, 'error' | 'onChange'>
> & {
  defaultValue?: string
  helperText?: string
  label?: string
  labelOptions?: InputLabelProps
  withPadding?: boolean
  disabled?: boolean
  options?: SelectOption[]
  error?: string
  multiple?: boolean
  onChange: (value: string) => void
  name: string
  'data-test-id'?: string
}

const Select = forwardRef<HTMLInputElement, Props>(function Select(
  {
    helperText,
    error,
    label,
    labelOptions,
    withPadding = true,
    disabled = false,
    multiple,
    options = [],
    onChange,
    'data-test-id': testId,
    ...rest
  },
  ref
) {
  return (
    <PlainSelect
      {...rest}
      label={label}
      labelOptions={labelOptions}
      data-test-id={testId}
      error={error}
      disabled={disabled}
      helperText={helperText ?? undefined}
      multiple={multiple}
      options={options}
      withPadding={withPadding}
      ref={ref}
      onChange={onChange}
    />
  )
})

type PlainSelectProps<Value = unknown> = {
  helperText?: string
  error?: string
  label?: string
  labelOptions?: InputLabelProps
  withPadding?: boolean
  options?: SelectOption[]
  disabled?: boolean
  multiple?: boolean
  name?: string
  onChange: (value: string) => void
  'data-test-id'?: string
} & PropsWithChildren<Omit<SelectProps<Value>, 'error' | 'onChange'>>

export const PlainSelect = forwardRef<HTMLInputElement, PlainSelectProps>(
  function PlainSelect(
    {
      label,
      labelOptions,
      helperText,
      options = [],
      error,
      withPadding,
      multiple,
      onChange,
      value,
      disabled = false,
      name,
      'data-test-id': testId = '',
      children,
      ...rest
    },
    ref
  ) {
    const classes = useStyles()
    const { trackFieldBlur } = useTracking()

    const handleOnBlur = (event: React.FocusEvent<any, any>) => {
      if (name) {
        trackFieldBlur(name, TrackIdType.NAME)
      }

      if (rest.onBlur) {
        rest.onBlur(event)
      }
    }

    return (
      <FormControl
        fullWidth
        error={Boolean(error)}
        variant="outlined"
        className={`${withPadding && classes.root} ${
          classes.focusForHelperText
        }`}
      >
        <InputLabel {...labelOptions} disabled={disabled}>
          {label}
        </InputLabel>
        <SelectMUI
          {...rest}
          fullWidth
          required
          name={name}
          variant="outlined"
          label={label}
          value={value || ''}
          multiple={multiple}
          disabled={disabled}
          ref={ref}
          onChange={e => onChange(e.target.value as string)}
          onBlur={handleOnBlur}
          SelectDisplayProps={
            {
              'data-test-id': testId,
            } as MySelectDisplayProps
          }
        >
          {children
            ? children
            : options.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-test-id={option.label}
                >
                  {option.label}
                </MenuItem>
              ))}
        </SelectMUI>
        {Boolean(error) ? (
          <FormHelperText
            className={`${classes.errorText} helperText`}
            data-test-id={testId ? `${testId}-error-message` : 'error-message'}
          >
            {error}
          </FormHelperText>
        ) : (
          <FormHelperText
            className="helperText"
            data-test-id={testId ? `${testId}-helper-text` : 'helper-text'}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
)

export default Select
