import { makeStyles } from '@mui/styles'
import { SPACING } from '../../lib/theme'

type StyleProps = {
  isMobile?: boolean
}

// TODO: We don't seem to have appropriate sizes for these elements that can be imported.
// Discussion should be had around this.
const useStyles = makeStyles(theme => ({
  titleWrapper: ({ isMobile }: StyleProps) => ({
    padding: '0 !important',
    ...(isMobile && {
      marginTop: theme.spacing(SPACING.S),
    }),
  }),
  title: {
    padding: '0 0 45px',
  },
}))

export default useStyles
